@import './variables';
@import './components/styles-btn';
@import './components/styles-text';
@import './components/styles-fonts';
@import './components/styles-card';
@import './components/styles-input';
@import './components/styles-loading';
@import './components/styles-dropdown';
@import './components/styles-alert';
@import './components/styles-pagination';

.content {
  display: flex;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @media (max-width: $mobileMax) {
    height: auto;
    margin: 0 25rem;
    width: calc(100% - 50rem);
  }
}

html {
  font-size: 0.0520833vw;
  scroll-behavior: smooth;
  margin: 0;
  padding: 0;

  @media (max-width: $tabletMax) {
    font-size: 0.1012145vw;
  }

  @media (max-width: $mobileMax) {
    font-size: 0.2666666vw;
  }
}

*,
*:after,
*:before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  font-family: Poppins, sans-serif;
}

body {
  margin: 0;
  padding: 0;
  font-size: 16rem;
  line-height: normal;
  font-family: Poppins, sans-serif;
}

a {
  text-decoration: none;
  color: $black;
}

.bg-all {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
  width: 100%;
  height: 100vh;
  min-height: 100%;
  object-fit: cover;
}

@media only screen and (min-width: $tabletMax) {
  .hide-desktop {
    display: none !important;
  }
}

@media only screen and (max-width: $tabletMax) and (min-width: $mobileMax)  {
  .hide-tablet {
    display: none !important;
  }
}

@media (max-width: $mobileMax) {
  .hide-mobile {
    display: none !important;
  }
}

.left-block-home {
  position: absolute;
  left: 10%;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 160px;
  height: 600px;
}

.right-block-home {
  position: absolute;
  right: 10%;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 160px;
  height: 600px;
}

h1.logo {
  display: flex;
  align-items: center;
  gap: 16rem;
  font-family: 'Impact', sans-serif;
  text-transform: uppercase;
  letter-spacing: 6rem;
  font-weight: 500;


  img {
    height: 75rem;
  }
}



h1 {
  .title {
    font-family: 'Impact', sans-serif;
    display: flex;
    gap: 10rem;
    align-items: flex-end;

    img {
      width: 25rem;
      height: fit-content !important;
      margin-bottom: 8rem;
    }
  }
}
