@import '../variables';

h1, h2, h3, h4, h5, p {
  margin: 0;
  padding: 0;
}


h1 {
  font-size: 37rem;
  font-weight: bold;
  line-height: normal;

  @media (max-width: $tabletMax) {
    font-size: 32rem;
  }

  @media (max-width: $mobileMax) {
    font-size: 28rem;
  }
}

p {
  font-size: 16rem;
  font-weight: 400;
  line-height: normal;

  @media (max-width: $mobileMax) {
    font-size: 15rem;
  }
}
