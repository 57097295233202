@import '../variables';

.pagination {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-bottom: 40rem;
  margin-top: 20rem;
  gap: 5rem;

  .pagination-item {
    width: 42rem;
    height: 42rem;
    background-color: $white;
    color: $black;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    user-select: none;
    border-radius: 10px;
    font-size: 18px;
    font-weight: bold;
    border: 1px solid $black;

    &.clickable {
      cursor: pointer;
    }

    &:hover {
      background-color: $black;
      color:$white;
    }

    &.current-page {
      background-color: $black;
      color:$white;
      cursor: default;
    }
  }
}
