//////////////////////////////////
///////////// COLOR //////////////
//////////////////////////////////

$grey: #f7f7f7;
$grey-dark: #e8e8e8;
$green: #80d95a;
$red: #df3030;
$yellow: #f1e14f;
$yellow-light: #FFFCAF;
$grey-dark: #adadad;
$brown: #c49f6e;

$white: #ffffff;
$black: #000000;

//////////////////////////////////
////////// TRANSITION ////////////
//////////////////////////////////

$timeTransitionShort: 0.3s;

//////////////////////////////////
////////// RESPONSIVE ////////////
//////////////////////////////////

$desktopMax: 1440px;

$tabletMax: 992px;

$mobileMax: 480px;

$header: 130rem;
$headerMobile: 123rem;
