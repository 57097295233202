@import '../variables';

input[type='text'], input[type='password'] {
  width: 100%;
  border: 2px solid rgba(255, 255, 255, 0);
  border-radius: 30px;
  padding: 16rem 38rem;
  outline: none;

  &.error {
    border: solid 2px $red;
  }

  &.success, &.success:focus-visible {
    border: solid 2px $green;
  }
}
