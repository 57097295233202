@import '../variables';

.alert {
  text-align: left;
  background: white;
  padding: 10rem 20rem;
  border-radius: 20px;
  border: 2px solid rgba(0, 0, 0, 0);

  &.alert-danger {
    border: 2px solid $red;
  }

  &.alert-success {
    border: 2px solid $green;
  }
}
