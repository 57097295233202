@import '../variables';

.link {
  font-size: 16rem;
  font-weight: 600;
  text-decoration: underline;
  width: fit-content;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
}

.btn {
  border-radius: 22.5px;
  font-family: Poppins, sans-serif;
  font-size: 16rem;
  font-weight: bold;
  padding: 15rem;
  text-align: center;
  background-color: $white;
  box-shadow: 0 2px 10px 0 #e2e2e2;
  border: none;
  outline: none;
  cursor: pointer;
  color: $black;

  @media (max-width: $tabletMax) {
    padding: 10rem 20rem
  }


  &.dark {
    background-color: $black;
    color: $white;
    box-shadow: none;
  }
}
