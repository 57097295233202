@import 'src/assets/styles/variables';

.dropdown {
  position: relative;
  width: fit-content;

  .dropdown-title {
    &.link {
      display: inline-flex;
      align-items: center;
      user-select: none;
      justify-content: space-between;
      cursor: pointer;
    }
  }

  .dropdown-content {
    font-size: 14rem;
    position: absolute;
    z-index: 2;
    width: max-content;
    top: 60rem;
    left: 0;
    border-radius: 10rem;
    box-shadow: 0 3rem 6rem 0 rgb(0 0 0 / 16%);
    background-color: $white;
    max-height: 0;
    overflow-x: hidden;
    overflow-y: auto;
    -ms-overflow-style: none;
    color: $black;
    overscroll-behavior: none;
    display: flex;
    flex-direction: column;

    &.dropdown-content-link {
      left: initial;
      right: 0;
    }

    .classic-content {
      padding: 22rem 17rem;

      .item {
        white-space: nowrap;
      }
    }

    &::-webkit-scrollbar {
      display: none;
    }

    a {
      display: block;
    }
  }

  &.show .dropdown-content {
    transition: max-height 0.3s ease;
    max-height: 550rem;
  }
}
